import React from 'react';
import Helmet from 'react-helmet';

const SeoData = {
  description:
    "Don't just survive, thrive! Receive personalised coaching from a qualified professional to upskill your Parenting and enhance your Relationships",
  title: 'Parenting & Relationship Coaching | FREE Consultation',
  url: 'https://getsetthrive.life/',
  author: 'maninder singh',
  keywords: [
    'life coaching',
    'counselling',
    'relationship',
    'parenting',
    'children',
    'couple',
    'marriage',
    'coaching',
    'melbourne',
    'coach',
    'counsellor',
    'support',
    'help',
    'family',
    'conflict',
    'partner',
    'love',
    'free',
    'parent',
    'LGBTQIA+',
    'cultural',
    'diversity',
    'Indian',
    'skills',
    'strategies',
    'professional',
    'wellness',
    'wellbeing',
    'care',
    'self help',
    'therapy',
    'child',
    'qualified',
    'social worker',
    'solution',
    'affordable',
    'private',
    'online',
    'personal',
    'consultation',
    'contact',
    'psychology',
    'mental health',
    'abuse',
    'family violence',
    'holistic',
    'gottman',
    'change',
    'teenager',
    'step parent',
    'step family',
    'confidence',
    'discipline',
    'communication',
    'argument',
    'break-up',
    'seperation',
    'divorce',
    'improve',
  ],
  img: '',
  facebookId: 'getsetthrive',
};

const SeoComponent = () => {
  return (
    <Helmet>
      <meta property="fb:app_id" content={SeoData.facebookId} />
      <meta property="og:title" content={SeoData.title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={SeoData.url} />
      <meta property="og:image" content={SeoData.img} />
      <meta property="og:description" content={SeoData.description} />
      <meta name="description" content={SeoData.description} />
      <meta name="keywords" content={SeoData.keywords.join(', ')} />
      <meta name="author" content={SeoData.author} />
      <title>{SeoData.title}</title>
      <html lang="en" />
    </Helmet>
  );
};

export default SeoComponent;
